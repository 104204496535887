import { createSlice } from '@reduxjs/toolkit';
import interPretor from "../../interpretor/interPretor";
import { dispatchSnackbar } from './snackbar';


// ----------------------------------------------------------------------

const initialState = {
  mode: "",
  editUserId: null,
  lookUp: [],
  editUserDetails: null,
  submitloading: false,
  loading: false,
  error: null,
  deleteLoading: false,
  openModal: false,
  openDeleteModal:false,
  openResetPasswordModal:false,
  resetUserEmail:null,
};

const addUserReducer = createSlice({
  name: 'addUser',
  initialState,
  reducers: {
    setLookUp(state, action) {
      state.lookUp = action.payload
    },
    setEditUserDetails(state, action) {
      state.editUserDetails = action.payload
    },
    setMode(state, action) {
      state.mode = action.payload;
    },
    setUserId(state, action) {
      state.editUserId = action.payload
    },
    setSumbitLoading(state, action) {
      state.submitloading = action.payload
    },
    setOpenModal(state, action) { 
      state.openModal = action.payload;
    },
    setOpenResetPasswordModal(state, action) { 
      state.openResetPasswordModal = action.payload.openResetPasswordModal;
      state.resetUserEmail = action.payload.resetUserEmail
    },
    setOpenDeleteModal(state,action) {
      state.openDeleteModal = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setDeleteLoading(state, action) {
      state.deleteLoading = action.payload;
    },
    resetAddUser(state) {
      return initialState;
    },
  },
});

// Reducer

// Actions
export const { setLookUp, setEditUserDetails, setMode,setOpenModal, resetAddUser, setDeleteLoading, setSumbitLoading, setLoading, setUserId,setOpenDeleteModal,setOpenResetPasswordModal} = addUserReducer.actions;

// ----------------------------------------------------------------------
export const getRolesList = async (dispatch) => {
  try {
    const response = await interPretor.get(`/user/lookup/role`);
    if (response.ok) {
      if (response?.data?.data) {
        dispatch(setLookUp(response.data.data))
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export const getEditUserDetails = async (userId, dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await interPretor.get(`/user/${userId}`);
    if (response.ok) {
      if (response?.data?.data) {
        dispatch(setEditUserDetails(response.data.data))
      }
    }
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    console.log(error)
  }
}

export const postAddUser = async (formData, dispatch, history) => {
  dispatch(setSumbitLoading(true))
  try {

    const res = await interPretor.post("/register", formData);
    if (res.ok) {
      dispatch(setMode(""));
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "User Added Successfully.",
          snackbarseverity: "success",
        })
      )
      history.back();
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to login, please try again.",
            snackbarseverity: "error",
          })
        )
      }
    }
    dispatch(setSumbitLoading(false))
  } catch (error) {
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    dispatch(setSumbitLoading(false));
    console.log(error)
  }
}

export const putAddUser = async (userId, formData, dispatch, history) => {
  dispatch(setSumbitLoading(true))
  try {

    const res = await interPretor.put(`/user/${userId}`, formData);
    if (res.ok) {
      dispatch(setMode(""));
      dispatch(setUserId(null));
      dispatch(setEditUserDetails(null))
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "User details updated successfully",
          snackbarseverity: "success",
        })
      )
      history.back();
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to update the user details, please try again.",
            snackbarseverity: "error",
          })
        )
      }
    }
    dispatch(setSumbitLoading(false))
  } catch (error) {
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    dispatch(setSumbitLoading(false));
    console.log(error)
  }
}

export const upDateUserStatus = async (userIds, dispatch) => {
  dispatch(setDeleteLoading(true));
  try {
    const res = await interPretor.patch(`/user/status`,userIds);
    if (res.ok) {
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "User Status Updated Successfully.",
          snackbarseverity: "success",
        })
      )
      dispatch(setDeleteLoading(false));
      dispatch(setOpenDeleteModal(false));
      dispatch(setOpenModal(false));
      return true
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to update user status, please try again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setDeleteLoading(false));
      return false;
    }
  } catch (error) {
    dispatch(setDeleteLoading(false));
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error);
    return false;
  }
}

export const reSetUserPassword = async (data,dispatch) => {
  dispatch(setSumbitLoading(true));
  try{
    const res = await interPretor.put("/user/reset_password",data)
    if (res.ok) {
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "Password updated Successfully.",
          snackbarseverity: "success",
        })
      );
      dispatch(setSumbitLoading(false));
      dispatch(setOpenResetPasswordModal({openResetPasswordModal:false,resetUserEmail:null}));
      return true;
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      }else{
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Request Failed, please try again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setSumbitLoading(false));
      return false;
    }
    
  }catch(error){
    dispatch(dispatchSnackbar({
      snackbarOpen: true,
      snackbarTitle: "Something went wrong, please try again.",
      snackbarseverity: "error",
    }))
    dispatch(setLoading(false));
    console.log(error);
    return false;
  }
}

export default addUserReducer.reducer;


