import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
userDetails:{
    remember: false,
    username: "",
    password: "",
},
};

const snackbarReducer = createSlice({
  name: 'remember',
  initialState,
  reducers: {
    dispatchUserDetails(state, action) {
      state.userDetails.remember = action.payload.remember;
      state.userDetails.username = action.payload.username;
      state.userDetails.password = action.payload.password;
    },
    resetUserDetails(state) {
      return initialState;
    },
  },
});

// Reducer
export default snackbarReducer.reducer;

// Actions
export const { dispatchUserDetails ,resetUserDetails} = snackbarReducer.actions;

// ----------------------------------------------------------------------

