import React from "react";


import {  lazy } from 'react';
import { Outlet } from 'react-router-dom';

// layouts
import DashboardLayout from '../../layout/main/sidebar';
import PrivateRoute from '../../components/route-restriction'
// components
import Loadable from 'src/components/suspense/Index';

// ----------------------------------------------------------------------
const Dashboard = Loadable(lazy(() => import("../../pages/dashboard/Index")));
const IndexPage = Loadable(lazy(() => import("../../pages/invoice/Index")));
const UserPage = Loadable(lazy(() => import("../../pages/user/Index")));
const CreateUserPage = Loadable(lazy(() => import("../../pages/user/CreateUser")));
const ProfilePage = Loadable(lazy(() => import ("../../pages/user/Profile")));
const ProjectListPage = Loadable(lazy(() => import ("../../pages/projects/Index")));


// ----------------------------------------------------------------------

export const mainRoutes = (role) => {
if(role === "SUPER_ADMIN"){
  return [
    {
      path: 'app',
      element: (
        <PrivateRoute>
          <DashboardLayout>        
              <Outlet />        
          </DashboardLayout>
         </PrivateRoute>
      ),
      children: [
        { element: <Dashboard />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'user', element: <UserPage /> },
        { path: 'project', element: <ProjectListPage /> },
        { path: 'create-user', element: <CreateUserPage /> },
        { path: 'profile', element: <ProfilePage /> }
      ],
    },
  ]
}else{
  return [
    {
      path: 'app',
      element: (
        <PrivateRoute>
          <DashboardLayout>        
              <Outlet />        
          </DashboardLayout>
         </PrivateRoute>
      ),
      children: [
        { element: <Dashboard />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'document', element: <IndexPage /> },
        { path: 'project', element: <ProjectListPage /> },
        { path: 'profile', element: <ProfilePage /> }
      ],
    },
  ]
}

}
