import React from "react";

import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import AuthLayout from '../../layout/auth';
import Loadable from 'src/components/suspense/Index';
import PrivateRoute from '../../components/route-restriction'


// ----------------------------------------------------------------------

// JWT
const LoginPage = Loadable(lazy(() => import('../../pages/login/Index')));
const ForgetPasswordPage = Loadable(lazy(() => import('../../pages/login/ForgetPassword')));

// ----------------------------------------------------------------------




export const authRoutes = [
  {
    path: '/',
    element: (
      <PrivateRoute>
        <AuthLayout>        
            <Outlet />        
        </AuthLayout>
      </PrivateRoute>
    ),
    children: [
      { element: <LoginPage />, index: true },  

    ],
  },
  // {
  //   path: '/forget-password',
  //   element: (
  //       <AuthLayout>        
  //           <Outlet />        
  //       </AuthLayout>
  //   ),
  //   children: [
  //     { element: <ForgetPasswordPage />, index: true },  

  //   ],
  // }
];