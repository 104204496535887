const config = {
    basename: '/app',
    authPath: "/",
    dashboardPath:"/app",
    defaultSettings:{
      themeMode: 'light', // 'light' | 'dark'
      themeDirection: 'ltr', //  'rtl' | 'ltr'
      themeContrast: 'default', // 'default' | 'bold'
      themeLayout: 'mini', // 'vertical' | 'horizontal' | 'mini'
      themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
      themeStretch: false,
    }
  };
  
  export default config;
  