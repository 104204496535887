import React from "react";

import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from '../../layout/error';
import Loadable from 'src/components/suspense/Index';

// ----------------------------------------------------------------------

const Page404 = Loadable(lazy(() => import('../../pages/404')));

// ----------------------------------------------------------------------

export const errorRoutes = [
  {
    path: 'error',
    element: (
      <CompactLayout>
      <Outlet />
    </CompactLayout>
    ),
    children: [
      { element: <Page404 />, index: true },  

    ],
  },
];