import React from "react";

import config from '../config';
import SvgColor from 'src/components/svg-color';
import dashbordIcon from '../assets/icons/dashboard-icon.svg'

const Icon = () => (
  <SvgColor src={dashbordIcon} sx={{ width: 1, height: 1 }} />
);
export const dashboard = {
    subheader: '',
    items: [
      { title: 'dashboard', path:[`${config.dashboardPath}/dashboard`], icon:dashbordIcon },
    ],
  }

