import React from "react";

import config from '../config';
import SvgColor from 'src/components/svg-color';
import documentIcon from '../assets/icons/document-icon.svg'

const Icon = () => (
  <SvgColor src={documentIcon} sx={{ width: 1, height: 1 }} />
);
export const invoice = {
    subheader: '',
    items: [
      { title: 'Documents', path:[`${config.dashboardPath}/document`], icon:documentIcon },
    ],
  }

