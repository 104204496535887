import React from "react";

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import logoImage from 'src/assets/icons/logo_black.png'
import smallLogo from 'src/assets/icons/small_logo.png'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx,from, ...other }, ref) => {
  const theme = useTheme();
  console.log("from",from)

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 92,
        marginBottom:32,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img src={from === "mini" ? smallLogo:logoImage} alt="logo" />
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link   sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  from:PropTypes.string
};

export default Logo;
