

import { resetInvoice } from './invoice';
import { resetLogin } from './login';
import { resetSnackbar } from './snackbar';
import { resetUser } from './user';
import { resetAddUser } from './addUser';
import { resetLoggedUserDetails } from './loggedUser';
import { resetProject } from './project';

export const logout = () => (dispatch) => {
  
  dispatch(resetInvoice());
  dispatch(resetLogin());
  dispatch(resetSnackbar());
  dispatch(resetUser());
  dispatch(resetAddUser());
  dispatch(resetLoggedUserDetails());
  dispatch(resetProject());

  // Dispatch reset actions for other slices as needed
};
