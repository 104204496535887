import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
snackbar:{
    snackbarOpen: false,
    snackbarTitle: "",
    snackbarseverity: "",
},
};

const snackbarReducer = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    dispatchSnackbar(state, action) {
      state.snackbar.snackbarOpen = action.payload.snackbarOpen;
      state.snackbar.snackbarTitle = action.payload.snackbarTitle;
      state.snackbar.snackbarseverity = action.payload.snackbarseverity;
    },
    resetSnackbar(state) {
      return initialState;
    },
  },
});

// Reducer
export default snackbarReducer.reducer;

// Actions
export const { dispatchSnackbar ,resetSnackbar} = snackbarReducer.actions;

// ----------------------------------------------------------------------

