import React from "react";
import { useSelector } from 'react-redux';

import { Navigate, useRoutes } from 'react-router-dom';
// config
//
import { errorRoutes } from './error';

import { authRoutes } from './auth';
import { mainRoutes } from './main';

// ----------------------------------------------------------------------

export default function Router() {
  const {currentUserDetails} = useSelector(state => state.loggedUser);
  const { isLogged } = useSelector(state => state.login);
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={"/"} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...mainRoutes(currentUserDetails?.role?.code),

    // Error routes
    ...errorRoutes,

    // No match 404
    { path: '*', element: <Navigate to={isLogged ? "/404" : "/"} replace /> },
  ]);
}
