import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
// import logger from 'redux-logger'; // Example additional middleware

import { rootPersistConfig, rootReducer } from './reducer';

// const middleware = [...getDefaultMiddleware(), thunk, logger]; // Add additional middleware to the array
const middleware = [...getDefaultMiddleware(), thunk]; // Add additional middleware to the array

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
