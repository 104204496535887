import React from 'react';

// routes
import Router from 'src/routes/link';
// theme
import ThemeProvider from 'src/theme';
// redux

// components
import { SettingsProvider } from 'src/components/settings';
import { useSelector,useDispatch } from 'react-redux';
import { resetSnackbar } from './store/slice/snackbar';
import CustomizedSnackbars from './components/snackbar';

// ----------------------------------------------------------------------

export default function App() {
  const {snackbarOpen,snackbarTitle,snackbarseverity} = useSelector(state => state.snackbar.snackbar)
  const dispatch = useDispatch();

  const handleCloseSnackbar = () => {
    dispatch(resetSnackbar())
  }
  return (
      <SettingsProvider>
        <ThemeProvider>
          <Router />
          <CustomizedSnackbars open={snackbarOpen} onClose={handleCloseSnackbar} title={snackbarTitle} severity={snackbarseverity} />
        </ThemeProvider>
      </SettingsProvider>
  );
}
