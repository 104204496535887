import { createSlice } from '@reduxjs/toolkit';
import interPretor from "../../interpretor/interPretor";
import { dispatchSnackbar } from './snackbar';
import { getProjectLookUp } from './invoice';


// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    data: [],
    totalCount:0,
    error: null,
    addOrEditLoading:false,
    mode:"",
    title:"",
    projectDetails:null,
    openAddModal:false,
    openStatusModal:false,
    deleteLoading: false,
    openDeleteModal:false
};

const projectReducer = createSlice({
  name: 'project',
  initialState,
  reducers: {
    dispatchProcectData(state, action) {
      const { loading, data, error,totalCount } = action.payload;
      state.loading = loading;
      state.data = data;
      state.totalCount = totalCount;
      state.error = error;
    },
    setAddOrEditLoading(state, action){
      state.addOrEditLoading = action.payload;
    },
    setOpenStatusModal(state, action){
      state.openStatusModal = action.payload;
    },
    setProjectState(state, action){
      state.mode = action.payload.mode;
      state.projectDetails = action.payload.projectDetails;
      state.title = action.payload.title;
      state.openAddModal = action.payload.openAddModal;
    },
    setDeleteLoading(state, action) {
      state.deleteLoading = action.payload
    },
    setOpenDeleteModal(state, action) {
      state.openDeleteModal = action.payload;
    },
    resetProject(state) {
      return initialState;
    },
  },
});

// Reducer
export default projectReducer.reducer;

// Actions
export const { dispatchProcectData,resetProject,setAddOrEditLoading,setProjectState,setOpenStatusModal,setDeleteLoading,setOpenDeleteModal} = projectReducer.actions;

export async function getProjectsList (page_num,page_size,search_keyword,sort_order,sort_by,dispatch) {
    function removeUndefinedKeys(obj) {
      for (const key in obj) {
        if (obj[key] === undefined) {
          delete obj[key];
        }
      }
    }
    const queryParams = {
      page_num: page_num || undefined,         // Include 'page_num' if it exists, or undefined if not
      page_size: page_size || undefined,       // Include 'page_size' if it exists, or undefined if not
      search_keyword: search_keyword || undefined, // Include 'search_keyword' if it exists, or undefined if not
      sort_order: sort_order || undefined,     // Include 'sort_order' if it exists, or undefined if not
      sort_by: sort_by || undefined            // Include 'sort_by' if it exists, or undefined if not
    };
  
    removeUndefinedKeys(queryParams);
    const queryString = new URLSearchParams(queryParams).toString();
    // dispatch(dispatchInvoiceData({ loading: true })); // Dispatch loading state
    dispatch(
      dispatchProcectData({
      loading: true, // Dispatch loading state again to set it to false
      data: [],
      totalCount:0, // Dispatch empty data
      error: null, // Dispatch error
    })
  );
    try {
      const response = await interPretor.get(`/document/project/list?${queryString}`);
      if(response.ok){
          if(response?.data?.data){
              dispatch(
                  dispatchProcectData({
                  data: response?.data?.data?.records,
                  totalCount:response?.data?.data?.total_count, // Dispatch invoice data
                  error: null, // Dispatch null for error
                  loading: false, // Dispatch loading state again to set it to false
                })
              );
          }  
      }else{
        dispatch(
          dispatchProcectData({
          data: [],
          totalCount:0, // Dispatch empty data
          error: null, // Dispatch error
          loading: false, // Dispatch loading state again to set it to false
        })
        )
      }
      
    } catch (error) {
      dispatch(
          dispatchProcectData({
          loading: false, // Dispatch loading state again to set it to false
          data: [],
          totalCount:0, // Dispatch empty data
          error: error, // Dispatch error
        })
      );
    }
  
}

export const postAddProject = async (formData, dispatch) => {
  dispatch(setAddOrEditLoading(true))
  try {

    const res = await interPretor.post("/document/project/", formData);
    if (res.ok) {
      await getProjectLookUp(dispatch)
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "Project Added Successfully.",
          snackbarseverity: "success",
        })
      )
     dispatch(setAddOrEditLoading(false))
     dispatch(setProjectState({mode:"",projectDetails:null,title:"",openAddModal:false}));
     return res;
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to add project, please try again.",
            snackbarseverity: "error",
          })
        )
      }
    dispatch(setAddOrEditLoading(false));
    return false;
    }
  } catch (error) {
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    dispatch(setAddOrEditLoading(false));
    console.log(error)
    return false;
  }
}

export const patchProjectDetails = async (projectId,formData,type, dispatch, ) => {
  dispatch(setAddOrEditLoading(true))
  try {

    const res = await interPretor.patch(`/document/project/${projectId}`, formData);
    if (res.ok) {
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: type === "form" ? "Project Updated Successfully." : "Project statua Updated Successfully",
          snackbarseverity: "success",
        })
      )
      dispatch(setAddOrEditLoading(false));
      dispatch(setProjectState({mode:"",projectDetails:null,title:"",openAddModal:false}));
      dispatch(setOpenStatusModal(false));
      return res;
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle:  type === "form" ? "Failed to update project, please try again." : "Failed to update project status, please try again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setAddOrEditLoading(false));
      return false;
    }
  } catch (error) {
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    dispatch(setAddOrEditLoading(false));
    console.log(error)
    return false;
  }
}

export async function deleteSelectedProjects(data, dispatch) {
  dispatch(setDeleteLoading(true));
  try {
    const res = await interPretor.delete(`/document/project?project_ids=${data}`);
    if (res.ok) {
      dispatch(dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Projects deleted Successfully.",
        snackbarseverity: "success",
      }))
      dispatch(setOpenDeleteModal(false));
      dispatch(setDeleteLoading(false));
      return true
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to delete Projects, please try again.",
            snackbarseverity: "error",
          })
        )
      }
    dispatch(setDeleteLoading(false));
      return false
    }
  } catch (error) {
    dispatch(setDeleteLoading(false));
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error);
    return false;
  }
}



