import { createSlice } from '@reduxjs/toolkit';
import { getCurrentUserDetails } from './login';
import { dispatchSnackbar } from './snackbar';
import interPretor from "../../interpretor/interPretor";

// ----------------------------------------------------------------------

const initialState = {
  currentUserDetails: null,
  deleteloading: false,
  openModal: false,
  updateLoading:false,
};

const loggedUserReducer = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {
    dispatchLoggedUserDetails(state, action) {
      state.currentUserDetails = action.payload;
    },
    setDeleteLoading (state, action) {
      state.deleteloading = action.payload;
    },
    setUpdateLoading (state, action) {
      state.updateLoading = action.payload;
    },
    setOpenModal(state, action) { 
      state.openModal = action.payload;
    },
    resetLoggedUserDetails(state) {
      return initialState;
    },
  },
});

// Reducer
export default loggedUserReducer.reducer;

// Actions
export const { dispatchLoggedUserDetails, resetLoggedUserDetails,setDeleteLoading,setOpenModal,setUpdateLoading } = loggedUserReducer.actions;

// ----------------------------------------------------------------------

export const putProfile = async (userId, formData, dispatch) => {
  dispatch(setUpdateLoading(true));
  try {

    const res = await interPretor.patch(`/profile/${userId}`, formData);
    if (res.ok) {
      await getCurrentUserDetails(dispatch);
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "Profile Image has Updated.",
          snackbarseverity: "success",
        })
      )
      dispatch(setUpdateLoading(false));
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to Upload Profile Image,Please Try Again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setUpdateLoading(false));
    }
    
  } catch (error) {
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    dispatch(setUpdateLoading(false));
    console.log(error)
  }
}



export const deleteProfile = async (userId, dispatch) => {
  dispatch(setDeleteLoading(true));
  try {

    const res = await interPretor.delete(`/profile/${userId}`,);
    if (res.ok) {
      getCurrentUserDetails(dispatch);
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "Profile Image has Deleted.",
          snackbarseverity: "success",
        })
      )
      dispatch(setOpenModal(false));
      dispatch(setDeleteLoading(false));
      return true;
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to Upload Profile Image,Please Try Again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setDeleteLoading(false));
      return false;
    }
    
  } catch (error) {
    dispatch(setDeleteLoading(false));
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error)
    return false;
  }
}
