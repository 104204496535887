import { createSlice } from '@reduxjs/toolkit';
import interPretor from "../../interpretor/interPretor";
import { dispatchSnackbar } from './snackbar';
import { dispatchUserDetails } from './remember';
import { dispatchLoggedUserDetails } from './loggedUser';
import { getDocumentCountWithStatus } from './invoice';
import { getRolesList } from './addUser';
import Cookies from "js-cookie";

// ----------------------------------------------------------------------


const initialState = {
  loading:false,
  isLogged: false,
  access_token: "",
  refresh_token: "",
  access_token_expire_time: null,
  openLogOutModal:false
};

const loginReducer = createSlice({
  name: 'login',
  initialState,
  reducers: {
    dispatchLoginId(state, action) {
      state.isLogged = action.payload.isLogged;
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.access_token_expire_time = action.payload.access_token_expire_time;
    },
    resetLogin(state) {
      return initialState;
    },
    setOpenLogoutModal(state,action) {
      state.openLogOutModal = action.payload
    },
    setLoading(state,action) {
      state.loading = action.payload
    }
  },
});

// Reducer
export default loginReducer.reducer;

// Actions

export const postLogin = async (data, dispatch, history) => {
  dispatch(setLoading(true))
  try {
    const formData = new FormData()
    formData.append("email",data.username);
    formData.append("password",data.password)
    const res = await interPretor.post("/login", formData);
    if (res.ok) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1); // One day from now
      Cookies.set("token",res?.data?.data?.access_token,{ expires: expirationDate });
      dispatch(dispatchLoginId({
        isLogged: true,
        ...res?.data?.data
      }))
      await interPretor.setHeader(
        "Authorization",
        `Bearer ${res?.data?.data?.access_token}`
      )
      if (data.remember === true) {
        dispatch(
          dispatchUserDetails({
            remember: true,
            username: data.username,
            password: data.password,
          })
        )
      } else {
        dispatch(
          dispatchUserDetails({
            remember: false,
            username: "",
            password: "",
          })
        )
      }
      const currentUser = await getCurrentUserDetails(dispatch);
      await getRolesList(dispatch);
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "Login Successfull",
          snackbarseverity: "success",
        })
      )
      if(currentUser?.role?.code === "SUPER_ADMIN"){
        history.push("/app/project");
      }else if(currentUser?.role?.code === "EDITOR"){
        await getDocumentCountWithStatus("",dispatch);
        history.push("/app/document");
      }else{
        history.push("/app/project");
      }
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      }else{
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to login, please try again.",
            snackbarseverity: "error",
          })
        )
      }
    }
    dispatch(setLoading(false))
  } catch (error) {
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    dispatch(setLoading(false));
    console.log(error)
  }
}

export const getCurrentUserDetails = async (dispatch) => {
  try{
     const res = await interPretor.get("/user/info")
     if(res.ok){
      if (res?.data?.data){
        dispatch(dispatchLoggedUserDetails(res.data.data))
        return res.data.data
      }else{
        return false
      }   
     }else{
      return false
     }
  }catch(error){
    console.log(error)
    return false
  }
}

export const reSetPassword = async (data,dispatch,history) => {
  dispatch(setLoading(true));
  try{
    const res = await interPretor.put("/user/forgot_password",data)
    if (res.ok) {
      dispatch(
        dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "Password updated, Plese Logged In",
          snackbarseverity: "success",
        })
      )
      history.replace("/");
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      }else{
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Request Failed, please try again.",
            snackbarseverity: "error",
          })
        )
      }
    }
    dispatch(setLoading(false));
  }catch(error){
    dispatch(dispatchSnackbar({
      snackbarOpen: true,
      snackbarTitle: "Something went wrong, please try again.",
      snackbarseverity: "error",
    }))
    dispatch(setLoading(false));
    console.log(error);
  }
}

export const { dispatchLoginId, resetLogin,setLoading,setOpenLogoutModal } = loginReducer.actions;

// ----------------------------------------------------------------------

