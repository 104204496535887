import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import loginReducer from './slice/login';
import snackbarReducer from './slice/snackbar';
import invoiceReducer from './slice/invoice'
import rememberReducer from "./slice/remember"
import userReducer from "./slice/user"
import loggedUserReducer from './slice/loggedUser';
import addUserReducer from './slice/addUser';
import projectReducer from "./slice/project"

export const rootPersistConfig = {
  key: 'root',
  storage,

};


const appReducer = combineReducers({
  login: loginReducer,
  snackbar: snackbarReducer,
  invoice: invoiceReducer,
  remember: rememberReducer,
  user: userReducer,
  loggedUser: loggedUserReducer,
  addUser: addUserReducer,
  project:projectReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'persist/REHYDRATE') {
    // Ignore the rehydration action
    return state;
  }
  if (action.type === 'RESET_REDUCERS') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

