import { createSlice } from '@reduxjs/toolkit';
import interPretor from "../../interpretor/interPretor";
import { dispatchSnackbar } from './snackbar';


// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    data: [],
    totalCount:0,
    error: null
};

const invoiceReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    dispatchUserData(state, action) {
      const { loading, data, error,totalCount } = action.payload;
      state.loading = loading;
      state.data = data;
      state.totalCount = totalCount;
      state.error = error;
    },
    
    resetUser(state) {
      return initialState;
    },
  },
});

// Reducer
export default invoiceReducer.reducer;

// Actions
export const { dispatchUserData,resetUser } = invoiceReducer.actions;

// ----------------------------------------------------------------------



export async function getUser (page_num,page_size,search_keyword,sort_order,sort_by,dispatch) {
  function removeUndefinedKeys(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  const queryParams = {
    page_num: page_num || undefined,         // Include 'page_num' if it exists, or undefined if not
    page_size: page_size || undefined,       // Include 'page_size' if it exists, or undefined if not
    search_keyword: search_keyword || undefined, // Include 'search_keyword' if it exists, or undefined if not
    sort_order: sort_order || undefined,     // Include 'sort_order' if it exists, or undefined if not
    sort_by: sort_by || undefined            // Include 'sort_by' if it exists, or undefined if not
  };

  removeUndefinedKeys(queryParams);
  const queryString = new URLSearchParams(queryParams).toString();
  // dispatch(dispatchInvoiceData({ loading: true })); // Dispatch loading state
  dispatch(
    dispatchUserData({
    loading: true, // Dispatch loading state again to set it to false
    data: [],
    totalCount:0, // Dispatch empty data
    error: null, // Dispatch error
  })
);
  try {
    const response = await interPretor.get(`/user/list?${queryString}`);
    if(response.ok){
        if(response?.data?.data){
            dispatch(
                dispatchUserData({
                data: response?.data?.data?.records,
                totalCount:response?.data?.data?.total_count, // Dispatch invoice data
                error: null, // Dispatch null for error
                loading: false, // Dispatch loading state again to set it to false
              })
            );
        }  
    }else{
      dispatch(
        dispatchUserData({
        loading: false, // Dispatch loading state again to set it to false
        data: [],
        totalCount:0, // Dispatch empty data
        error: null, // Dispatch error
      })
    );
    }
    
  } catch (error) {
    dispatch(
        dispatchUserData({
        loading: false, // Dispatch loading state again to set it to false
        data: [],
        totalCount:0, // Dispatch empty data
        error: "error", // Dispatch error
      })
    );
  }

}
