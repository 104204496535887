import { create } from "apisauce";
import Cookies from "js-cookie";

const token = Cookies.get("token");
const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const interPretor = create({
  baseURL:
    "https://ocr.legaleasesolutions.com:8000/api/v1",   
  timeout: 600000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
    "Timezone":systemTimeZone,
    Authorization: `Bearer ${token}`,
  },
});

export default interPretor;
