import React, { useEffect, useCallback, useState } from 'react'

import PropTypes from 'prop-types';
import { useRouter } from 'src/hooks/use-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Cookies from "js-cookie";
import { logout } from 'src/store/slice/logout';


export default function RouteRestriction({ children }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const location = useLocation();
  const [token,setToken] = useState(null);
  const {currentUserDetails} = useSelector(state => state.loggedUser);
  const { isLogged,access_token } = useSelector(state => state.login);
  const authPages = [
    "/", 
    // "/forget-password"
  ];

  const authenticationCheck = () => {
    let allow = false;
    if (authPages.includes(location.pathname)) {
      allow = true;
    }
    if (!allow) {
      if (isLogged === false) {
        router.replace('/');
      }
    } else if (isLogged === true && currentUserDetails?.role?.code) {
      router.replace(currentUserDetails?.role?.code === "SUPER_ADMIN" ?'/app/project' :'/app/document');
    }

  }

  const forcefullyLogoutorLoginFromAllTabs = () => {
    if (token === undefined) {
      router.replace('/');
      dispatch(logout());
    } else if (isLogged === false && token !== null) {
      // window.location.href = currentUserDetails?.role?.code === "SUPER_ADMIN" ?'/app/project' :'/app/document';
    }
  }


  useEffect(() => {
    authenticationCheck();
  }, [router, location, token]);

  useEffect(() => {
    const intervalFunction = () => {
      setToken(Cookies.get("token"))
    };

    const intervalId = setInterval(intervalFunction, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    forcefullyLogoutorLoginFromAllTabs()
  }, [token])
  return (
    <>{children}</>
  )
}
RouteRestriction.propTypes = {
  children: PropTypes.node,
};
