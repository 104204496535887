import React, {useEffect} from "react";

import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import {IconButton,Typography,Box,Button,Tooltip} from '@mui/material';
// routes
import { useRouter } from 'src/hooks/use-router';
// hooks
import { useMockedUser } from '../../hooks/use-mocked-user';
// auth
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import {logout} from 'src/store/slice/logout'
import {setOpenLogoutModal} from "../../store/slice/login"
import { useDispatch,useSelector } from 'react-redux';
import Cookies from "js-cookie";
import { ConfirmDialog } from "src/components/custom-dialog";


export default function AccountPopover() {
  const router = useRouter();
  const dispatch = useDispatch();
  const {currentUserDetails} = useSelector(state => state.loggedUser);
  const { openLogOutModal,access_token_expire_time } = useSelector(state => state.login);


  const { user } = useMockedUser();


  const popover = usePopover();

  const handleLogout = async () => {
    try {
      dispatch(logout());
      Cookies.remove("token");
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  function capitalizeFirstLetter(inputString) {
    // Check if the input is empty or null
    if (!inputString) {
      return inputString;
    }

    // Capitalize the first letter and concatenate it with the rest of the string
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

 
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      if ((currentTime >= parseInt(access_token_expire_time) * 1000) && openLogOutModal === false) {
        dispatch(setOpenLogoutModal(true))
      }
    }, 1000);
    return () => clearInterval(intervalId);
  },[Date.now()])

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={currentUserDetails?.profile_url? currentUserDetails?.profile_url:user?.photoURL}
          alt={user?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }} textAlign={"center"}>
        {currentUserDetails?.full_name?.length > 15 ?  <Tooltip arrow placement='top' title={capitalizeFirstLetter(currentUserDetails?.full_name)}><Typography variant="subtitle2"  noWrap>
                  {`${capitalizeFirstLetter(currentUserDetails?.full_name)?.slice(0,14)}...`}
                </Typography></Tooltip>:<Typography variant="subtitle2"  noWrap>
                  {capitalizeFirstLetter(currentUserDetails?.full_name)}
                </Typography>}

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentUserDetails?.role?.description}
          </Typography>
        </Box>

        <Divider  />

       

        

        <MenuItem
          onClick={() => {
            popover.onClose();
            router.push("/app/profile")
          }}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: '#032c3d' }}
        >
          My Profile
        </MenuItem>
        <Divider  />
        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: '#032c3d' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
                open={openLogOutModal}
                title="Session Timeout"
                content={
                    <>
                       Due to session timeout, please log in again.
                    </>
                }
                action={
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            //   handleDeleteRows();
                            handleLogout()
                        }}
                    >
                        Re-Login
                    </Button>
                }
            />
    </>
  );
}
