import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PropTypes from 'prop-types'; // @mui

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
   const {
    open,
    onClose,
    title,
    severity
} = props

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose()
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"right"}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {title}
        </Alert>
      </Snackbar>
    </Stack>
  );
}


CustomizedSnackbars.propTypes = {
    open: PropTypes.bool, 
    onClose: PropTypes.func,
    title: PropTypes.string,
    severity: PropTypes.string,
  };