import React from "react";

import config from '../config';
import SvgColor from 'src/components/svg-color';
import userIcon from '../assets/icons/user-icon.svg'

const Icon = () => (
  <SvgColor src={userIcon} sx={{ width: 1, height: 1 }} />
);
export const user = {
    subheader: '',
    items: [
        { title: 'users', path:[`${config.dashboardPath}/user`,`${config.dashboardPath}/create-user`], icon:userIcon },
      ],
  
  }

