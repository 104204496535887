import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { invoice } from './invoice'
import {user} from "./user"
import { projects } from './project';
import { dashboard } from './dashboard';

export function useNavData() {
  const {currentUserDetails} = useSelector(state => state.loggedUser);
  const menu = currentUserDetails?.role?.code === "SUPER_ADMIN" ? [projects,user] : [invoice,projects]
  const data = useMemo(
    () => menu,
    [currentUserDetails]
  );

  return data;
}

