import { createSlice } from '@reduxjs/toolkit';
import interPretor from "../../interpretor/interPretor";
import { dispatchSnackbar } from './snackbar';
import moment from "moment"


// ----------------------------------------------------------------------

const initialState = {
  invoice: {
    loading: false,
    tableLoading: false,
    data: [],
    totalCount: 0,
    error: null,
    uploadLoading: false,
    downLoadLoading: false,
    downloadAllLoading: false,
    lookUpData: [],
    statusLookUp: [],
    openDownloadExcelModal: false,
    openAlertForMultiDownload: false,
    openDeleteModal: false,
    downLoadTrackerData: null,
    documentCountWithStatus:null,
    Completed:0,
    Failed:0,
    Processing:0,
    openCancelModal:false
  },
};

const invoiceReducer = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    dispatchInvoiceData(state, action) {
      const { loading, data, error, totalCount } = action.payload;
      state.invoice.loading = loading;
      state.invoice.data = data;
      state.invoice.totalCount = totalCount;
      state.invoice.error = error;
    },

    setTableLoading(state, action) {
      state.invoice.tableLoading = action.payload
    },
    setOpenDownloadExcelModal(state, action) {
      state.invoice.openDownloadExcelModal = action.payload;
    },
    setOpenDeleteModal(state, action) {
      state.invoice.openDeleteModal = action.payload;
    },
    setOpenCancelModal(state, action) {
      state.invoice.openCancelModal = action.payload;
    },
    setOpenAlertForMultiDownload(state, action) {
      state.invoice.openAlertForMultiDownload = action.payload;
    },
    setDownLoadTrackerData(state, action) {
      state.invoice.downLoadTrackerData = action.payload;
    },
    setProjectLookUp(state, action) {
      state.invoice.lookUpData = action.payload
    },
    setProjectStatusLookUp(state, action) {
      state.invoice.statusLookUp = action.payload
    },
    setCountForStatus(state, action) {
        state.invoice[action.payload.status] = action.payload.count
    },
    setDocumentCountWithStatus(state, action) {
      state.invoice.documentCountWithStatus = action.payload
    },
    setDownLoadLoading(state, action) {
      state.invoice.downLoadLoading = action.payload
    },
    setDownLoadAllLoading(state, action) {
      state.invoice.downloadAllLoading = action.payload
    },
    setUploadLoading(state, action) {
      state.invoice.uploadLoading = action.payload
    },
    resetInvoice(state) {
      return initialState;
    },
  },
});

// Reducer
export default invoiceReducer.reducer;

// Actions
export const { dispatchInvoiceData, setOpenCancelModal,setTableLoading, resetInvoice, setUploadLoading, setDownLoadLoading, setDownLoadAllLoading, setProjectLookUp, setOpenDownloadExcelModal, setProjectStatusLookUp, setOpenAlertForMultiDownload, setOpenDeleteModal, setDownLoadTrackerData,setDocumentCountWithStatus,setCountForStatus} = invoiceReducer.actions;

export async function addFiles(file,project, dispatch) {
  dispatch(setUploadLoading(true));
  

  try {
    const res = await interPretor.post(`/document/upload/`, file);
    if (res.ok) {
      dispatch(dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "File uploaded successfully",
        snackbarseverity: "success",
      }));
      dispatch(setUploadLoading(false));
      await getDocumentCountWithStatus(project,dispatch);
      return true
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to upload file, please try again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setUploadLoading(false));
      return true;
    }
  } catch (error) {
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    dispatch(setUploadLoading(false));
    console.log(error);
  }
}

// ----------------------------------------------------------------------

export async function getTableInvoice(page_num, page_size, search_keyword, sort_order, sort_by, project_ids,status, loading, dispatch) {
  // dispatch(dispatchInvoiceData({ loading: true })); // Dispatch loading state

  function removeUndefinedKeys(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  const queryParams = {
    page_num: page_num || undefined,         // Include 'page_num' if it exists, or undefined if not
    page_size: page_size || undefined,       // Include 'page_size' if it exists, or undefined if not
    search_keyword: search_keyword || undefined, // Include 'search_keyword' if it exists, or undefined if not
    sort_order: sort_order || undefined,     // Include 'sort_order' if it exists, or undefined if not
    sort_by: sort_by || undefined,
    project_ids: project_ids || undefined,     // Include 'sort_by' if it exists, or undefined if not
    status:status === "All" ? undefined :status || undefined
  };

  removeUndefinedKeys(queryParams);
  const queryString = new URLSearchParams(queryParams).toString();
  if (loading === true) {
    dispatch(
      dispatchInvoiceData({
        data: [],
        totalCount: 0, // Dispatch empty data
        error: null, // Dispatch error
        loading: false, // Dispatch loading state again to set it to false
      })
    );
  }
  dispatch(setTableLoading(loading));

  try {
    const response = await interPretor.get(`/document/list?${queryString}`);
    if (response.ok) {
      dispatch(
        dispatchInvoiceData({
          data: response?.data?.data?.records,
          totalCount: response?.data?.data?.total_count, // Dispatch invoice data
          error: null, // Dispatch null for error
          loading: false, // Dispatch loading state again to set it to false
        })
      );
    }
    dispatch(setTableLoading(false));
  } catch (error) {
    dispatch(
      dispatchInvoiceData({
        data: [], // Dispatch empty data
        totalCount: 0,
        error: error, // Dispatch error
        loading: false, // Dispatch loading state again to set it to false
      })
    );
    dispatch(setTableLoading(false));
  }

}

export async function getAutoStatusChangedTableInvoice(page_num, page_size, search_keyword, sort_order, sort_by, project_ids,status,loading, dispatch) {
  // dispatch(dispatchInvoiceData({ loading: true })); // Dispatch loading state

  function removeUndefinedKeys(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  const queryParams = {
    page_num: page_num || undefined,         // Include 'page_num' if it exists, or undefined if not
    page_size: page_size || undefined,       // Include 'page_size' if it exists, or undefined if not
    search_keyword: search_keyword || undefined, // Include 'search_keyword' if it exists, or undefined if not
    sort_order: sort_order || undefined,     // Include 'sort_order' if it exists, or undefined if not
    sort_by: sort_by || undefined,
    project_ids: project_ids || undefined,      // Include 'sort_by' if it exists, or undefined if not
    status:status === "All" ? undefined :status || undefined
  };


  removeUndefinedKeys(queryParams);
  const queryString = new URLSearchParams(queryParams).toString();


  try {
    const response = await interPretor.get(`/document/list?${queryString}`);
    if (response.ok) {
      return response
    } else {
      return [];
    }
  } catch (error) {
    console.log(error)
  }
  return [];
}

export async function downloadExcelFile(data, dispatch) {
  function removeUndefinedKeys(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  const queryParams = {
    project_ids: data?.projectIds || undefined,
    status: data?.status || undefined,
    date: data?.date || undefined,
  };

  removeUndefinedKeys(queryParams);
  const queryString = new URLSearchParams(queryParams).toString();
  dispatch(setDownLoadLoading(true));
  try {
    const res = await interPretor.get(`/document/download-excel?${queryString}`, null, { timeout: 600000, responseType: 'blob' });
    if (res.ok) {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `LegaleaseSolutions_SummaryReport_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`; // Specify the desired file name
        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the blob URL
        window.URL.revokeObjectURL(url);
        dispatch(setOpenDownloadExcelModal(false));
        dispatch(dispatchSnackbar({
          snackbarOpen: true,
          snackbarTitle: "Files Downloaded Successfully.",
          snackbarseverity: "success",
        }))
      } else if (res.status === 204) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Sorry, no data was found for download.",
            snackbarseverity: "error",
          })
        )
      }
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to download files, please try again.",
            snackbarseverity: "error",
          })
        )
      }
    }
    dispatch(setDownLoadLoading(false));
  } catch (error) {
    dispatch(setDownLoadLoading(false));
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error);
  }
}

export const getProjectLookUp = async (dispatch) => {
  try {
    const res = await interPretor.get("/document/lookup/project")
    if (res.ok) {
      dispatch(setProjectLookUp(res?.data?.data));
    }
    const statusRes = await interPretor.get("/document/lookup/status")
    if (statusRes.ok) {
      dispatch(setProjectStatusLookUp(statusRes?.data?.data));
    }
  } catch (error) {
    console.log(error)
  }
}

export const getDocumentCountWithStatus = async (project_ids,dispatch) => {
  function removeUndefinedKeys(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  const queryParams = {
    project_ids: project_ids || undefined,     // Include 'sort_by' if it exists, or undefined if not
    
  };

  removeUndefinedKeys(queryParams);
  const queryString = new URLSearchParams(queryParams).toString();
  try {
    const res = await interPretor.get(`/document/count_documents?${queryString}`)
    if (res.ok) {
      if(res?.data?.data?.length > 0){
        for (let item of res.data.data){
          if(item.status === "Completed/Partially completed"){
            dispatch(setCountForStatus({status:"Completed",count:item.count}))
          }else if(item.status === "Processing/Queued"){
          dispatch(setCountForStatus({status:"Processing",count:item.count}))
          }else if(item.status === "Failed/Cancelled"){
            dispatch(setCountForStatus({status:"Failed",count:item.count}))
          }else{
            dispatch(setCountForStatus({status:item.status,count:item.count}))
          }
        }
       dispatch(setDocumentCountWithStatus(res?.data?.data));
       return res.data.data
      }else{
        return null;
      }
    }
  } catch (error) {
    console.log(error)
    return null;
  }
}

export async function downloadSelectedDocs(data, projectName, dispatch) {
  dispatch(setDownLoadLoading(true));
  try {
    const res = await interPretor.post("/document/document_list_download", data, { timeout: 600000, responseType: 'blob' });
    if (res.ok) {
      const blob = new Blob([res.data], { type: 'application/zip' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = `${projectName.name}.zip`; // Specify the desired file name
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(url);
      dispatch(setOpenAlertForMultiDownload(false));
      dispatch(dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Files Downloaded Successfully.",
        snackbarseverity: "success",
      }))
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to download files, please try again.",
            snackbarseverity: "error",
          })
        )
      }
    }
    dispatch(setDownLoadLoading(false));
  } catch (error) {
    dispatch(setDownLoadLoading(false));
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error);
  }
}

export async function downloadAllFile(url, filename,trackerId, dispatch) {
  console.log("ddd", url, filename)
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Get the response data as a Blob
    const zipBlob = await response.blob();

    // Create a link element to trigger the download
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(zipBlob);
    a.download = `${filename.name}.zip`;; // Specify the desired filename for the downloaded file
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    dispatch(setOpenAlertForMultiDownload(false));
    dispatch(setDownLoadAllLoading(false));
    dispatch(dispatchSnackbar({
      snackbarOpen: true,
      snackbarTitle: "Files Downloaded Successfully.",
      snackbarseverity: "success",
    }))
    deleteDownloadTracker(trackerId);
    dispatch(setDownLoadTrackerData(null))
  } catch (error) {
    console.error('Error downloading the file:', error);
    dispatch(setOpenAlertForMultiDownload(false));
    dispatch(setDownLoadAllLoading(false));
    dispatch(dispatchSnackbar({
      snackbarOpen: true,
      snackbarTitle: "Something went wrong, please try again.",
      snackbarseverity: "error",
    }))
    dispatch(setDownLoadTrackerData(null))
  }

}

export async function deleteDownloadTracker(trackerId) {
  try{
    const res = await interPretor.delete(`/document/download_tracker?tracker_id=${trackerId}`)
    if(res.ok){
      // console.log("deleted");
    }
  }catch(error){
    console.log(error);
  }
}

export async function downloadTracker(trackerId, projectName, dispatch) {
  try {
    const res = await interPretor.get(`/document/download_tracker?tracker_id=${trackerId}`)
    if (res.ok) {
      dispatch(setDownLoadTrackerData(res.data.data))
      if (res.data.data.status === "Completed") {
        downloadAllFile(res.data.data.zip_url, projectName,trackerId,dispatch)
      } else {
        downloadTracker(trackerId, projectName, dispatch);
      }
    }else{
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to download files, please try again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setDownLoadAllLoading(false));
      dispatch(setDownLoadTrackerData(null))
    }
  } catch (error) {
    dispatch(setDownLoadAllLoading(false));
    dispatch(setDownLoadTrackerData(null))
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error);
  }
}

export async function downloadAllDocs(data, projectName, dispatch) {
  dispatch(setDownLoadAllLoading(true));
  try {
    const res = await interPretor.post("/document/project_download", data, { timeout: 600000 });
    if (res.ok) {
      console.log("res", res)
      downloadTracker(res.data.data.inserted_id, projectName, dispatch);
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to download files, please try again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setDownLoadAllLoading(false));
    }

  } catch (error) {
    dispatch(setDownLoadAllLoading(false));
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error);
  }
}

export async function deleteSelectedDocs(data, dispatch) {
  dispatch(setDownLoadLoading(true));
  try {
    const res = await interPretor.delete(`/document/?doc_ids=${data}`);
    if (res.ok) {
      dispatch(dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Files deleted Successfully.",
        snackbarseverity: "success",
      }))
      dispatch(setOpenDeleteModal(false));
      dispatch(setDownLoadLoading(false));
      return true
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to delete files, please try again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setDownLoadLoading(false));
      return false
    }
  } catch (error) {
    dispatch(setDownLoadLoading(false));
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error);
    return false;
  }
}

export async function cancelDocs(data, dispatch) {
  dispatch(setDownLoadLoading(true));
  try {
    const res = await interPretor.patch(`/document/cancel?doc_ids=${data}`);
    if (res.ok) {
      dispatch(dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "File Processing Cancelled Successfully.",
        snackbarseverity: "success",
      }))
      dispatch(setOpenCancelModal(false));
      dispatch(setDownLoadLoading(false));
      return true
    } else {
      if (res?.data?.detail?.error?.description) {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: res?.data?.detail?.error?.description,
            snackbarseverity: "error",
          })
        )
      } else {
        dispatch(
          dispatchSnackbar({
            snackbarOpen: true,
            snackbarTitle: "Failed to  Cancel the file processing, please try again.",
            snackbarseverity: "error",
          })
        )
      }
      dispatch(setDownLoadLoading(false));
      return false
    }
  } catch (error) {
    dispatch(setDownLoadLoading(false));
    dispatch(
      dispatchSnackbar({
        snackbarOpen: true,
        snackbarTitle: "Something went wrong, please try again.",
        snackbarseverity: "error",
      })
    );
    console.log(error);
    return false;
  }
}



