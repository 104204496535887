import { useLocation, matchPath,matchPaths} from 'react-router-dom';

// ----------------------------------------------------------------------

export function useActiveLink(path, deep = true) {
  const { pathname } = useLocation();
  const findIndex = path.findIndex(item => item === pathname);
  const index = findIndex >= 0 ? findIndex : 0

  const normalActive = path ? !!matchPath({ path:path[index], end: true }, pathname) : false;

  const deepActive = path ? !!matchPath({ path:path[index], end: false }, pathname) : false;

  return deep ? deepActive : normalActive;
}
