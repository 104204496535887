import React from "react";

import config from '../config';
import SvgColor from 'src/components/svg-color';
import projectIcon from '../assets/icons/project-icon.svg'

const Icon = () => (
  <SvgColor src={projectIcon} sx={{ width: 1, height: 1 }} />
);
export const projects = {
    subheader: '',
    items: [
        { title: 'Projects', path:[`${config.dashboardPath}/project`], icon:projectIcon },
      ],
  
  }

